:root {
  --font-color: #3d3c47;
  --primary-orange: #ee4c25;
  --po-lighten: #fc5e37;
  --secondary-orange: #fb7729;
  --mv-gold: #eed9a9;
  --mv-gray: #505057;
  --mv-black: #252221;
}

@font-face {
  font-family: "Product Sans Regular";
  src: url(./assets/fonts/Product-Sans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Product Sans Bold";
  src: url(./assets/fonts/Product-Sans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Calibre-Regular";
  src: url(./assets/fonts/Calibre-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Calibre-RegularItalic";
  src: url(./assets/fonts/Calibre-RegularItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Graphik Semibold";
  src: url(./assets/fonts/Graphik-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: "Parisienne";
  src: url(./assets/fonts/Parisienne-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Calibre-Semibold";
  src: url(./assets/fonts/Calibre-Semibold.ttf) format("truetype");
}

.font-parisienne {
  font-family: "Parisienne" !important;
}

.font-calibre-regular {
  font-family: "Calibre-Regular" !important;
}

.font-calibre-semibold {
  font-family: "Calibre-Semibold" !important;
}

.font-calibre-italic {
  font-family: "Calibre-RegularItalic" !important;
}

.font-graphik-semibold {
  font-family: "Graphik Semibold" !important;
}

.product-sans-regular {
  font-family: "Product Sans Regular" !important;
}

.product-sans-bold {
  font-family: "Product Sans Bold" !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.image-container {
  position: relative;
}

.image-container .delete-button {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-container:hover .delete-button {
  display: block;
}

.custom-button {
  border: 1px solid transparent !important;
}

.custom-button:hover {
  background-color: transparent !important;
  color: black !important;
  border: 2px solid #e35738 !important;
}

.editor-class {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 1rem;
  border: 1px solid #ccc;
  height: "1200px";
}

input:focus {
  border-color: "red";
  outline: "none";
}