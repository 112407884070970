.mainWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.inputMainWrapper {
  width: 300px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  font-size: 14px;
  margin: 20px 0px 40px;
}

.inputBox {
  height: 32px;
  width: 32px;
  max-width: min-content;
  border: 1px solid black;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  appearance: textfield;
}

.inputBox::-webkit-outer-spin-button,
.inputBox::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.plus {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  border: 1px solid #0065cc;
}

.minus {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  border: 1px solid #0065cc;
}

.footerText {
  margin-top: 40px;
  box-sizing: border-box;
  font-weight: 600;
  width: 100%;
  background-color: rgb(239, 239, 239);
  text-align: center;
}

.disabled {
  cursor: not-allowed;
  border: 1px solid #939393;
}
